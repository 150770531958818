$container_space:20px; //페이지별 좌우 기본 여백
$black:#061933;
@media only screen and (max-width: 900px){
    .mobile_view{
        display:initial;
    }
    header{
        top:20px;
        left:20px;
        right:20px;
        padding:0px;
        .logo_section{
            img{
                width:120px;
            }
        }
        .main_menu{
            position: fixed;
            z-index: 10;
            top:0px;
            bottom:0px;
            left:0px;
            padding-left:$container_space;
            background-color: $black;
            width:100%;
            box-sizing: border-box;
            opacity: 0;
            pointer-events: none;
            transition-duration: 0.3s;

            display:flex;
            flex-direction: column;
            justify-content: center;
            gap:10px;
            li{
                font-size:32px;
                line-height: 48px;
                letter-spacing: 0.05em;
                &.active{
                    &:after{
                        display:none;
                        opacity: 0;
                    }
                }
                &:hover{
                    &.active,&:hover{
                        &:after{
                            display:none;
                            opacity: 0;
                        }
                    }
                }
            }

        }
        &.active{
            .mobile_menu{
                position: fixed;
                right:20px;
                top:20px;
               
                .mm_bar.mm1{
                    transform: translateY(7.5px) rotate(45deg);
                }
                .mm_bar.mm2{
                    transform: scaleX(0)
                }
                .mm_bar.mm3{
                    transform: translateY(-7.5px) rotate(-45deg);
                }
            }
            .main_menu{
                opacity: 1;
                pointer-events: initial;
            }
         
           
        }
        .mobile_menu{
            display:flex;
            flex-direction: column;
            gap:6px;
            position: relative;
            z-index: 20;
           
            .mm_bar{
                width:26px;
                height:1.5px;
                background-color: #fff;
                transition-duration: 0.2s;
                transform-origin: center center;
            }
        }
    }
    .contents_wrap{
        .back_img{
            &.back1{
                .back_container{
                    transform: translate(15%, -15%);
                    .cc_1,.cc_2{
                   
                        width:500px;
                        height:500px;
                        img{
                            width:500px; 
                        }
                    }
                    .cc_2{
                        top:185px;
                    }
                   
                }
            }
            &.back2{
                .back_container{
                    transform: translate(-85%, -150px);
                    .cc_1,.cc_2{
                        width:610px;
                        height:610px;
                        img{
                            width:610px;
                        }
                    }
                    .cc_2{
                        top:22px;
                            left:240px;
                    }
                  
                }
            }
            &.back3{
                .back_container{
                    transform: translate(-72%, -300px);
                    .cc_1,.cc_2{
                   
                        width:630px;
                        height:630px;
                        img{
                            width:630px;
                        }
                    }
                    .cc_2{
                        top:170px;
                            left:180px;
                    }
                   
                }
            }
        }
        .main{
            &.m1{
                padding:90px $container_space 0px;
                box-sizing: border-box;
                .main_copy{
                    font-size: 54px;
                    line-height: 49px;
                    letter-spacing: -0.009em;
                    br{
                        display:none;
                    }
                }
                .col2_section{
                    margin-top:80px;
                    flex-direction: column;
                    .section_item{
                        width:100%;

                        .section_title{
                            font-size:22px;
                            line-height: 48px;
                        }
                        .section_contents{
                            margin-top:10px;
                            font-size:15px;
                            line-height: 24px;;
                            br{
                                display:none;
                            }
                        }
                    }
                    .spec_items{
                        margin-top:40px;
                        width:100%;
                        .spec_item{
                            font-size:36px;
                            line-height: 28px;
                            padding:14px 0px 34px;
                            height:initial;

                        }
                    }
                }
            }
        }
        .about{
          
            &.a1{
                 padding:0px $container_space 0px;
                 .main_copy{
                     font-size:90px;
                     line-height: 78px;
                     img{
                         width:90%;
                     }
                 }
                 .main_desc{
                     font-size:28px;
                     line-height: 33px;
                     padding-left:0px;
                     margin-top:70px;
                     width:100%;
                     padding-right:0px;
                     br{
                         display:none;
                     }
                 }
            }
            &.a2{
                padding:0px;
                margin-top:79px;

                .divider_container{
                 
                  
                }
                .herit_items{
                    flex-direction: column;
                    margin-top:100px;
                    .herit_item{
                        display:flex;
                        gap:24px;
                        align-items: flex-start;
                        height:160px;
                        flex:initial;
                        box-sizing: border-box;
                        &:last-child{
                            height:130px;
                        }
                        .herit_title{
                            font-size:20px;
                            line-height: 28px;
                            width:165px;
                        }
                        .herit_contents{
                            margin-top:-8px;
                            flex:1;
                            flex-wrap: wrap;
                            align-items: flex-end;
                            justify-content: flex-start;
                            font-size:28px;
                            line-height: 30px;
                            gap:3px;
                            .herit_value{
                                display:block;
                                font-size:70px;
                            }
                            .herit_plus{
                                margin-left:3px;
                                font-size:70px;
                            }
                        }
                      
                    }
                }
                .partner_title{
                    margin-top:70px;
                    font-size:15px;
                    line-height: 24px;
                    padding-bottom:22px;
                }
                .partner_container{
                    flex-direction: column;
                    gap:0px;
                     

                    
                   
                    .partner_items{
                        width:100%;
                        margin-top:0px;

                        &.last_items{
                            border-top: none;

                        }
                        
                        .partner_item{
                            font-size: 30px;
                            line-height: 20px;
                            padding: 10px 0px 13px;
                            img{
                                margin-top:2px;
                                height:40px;
                            }
                        }
                    }
                    .partner_imgs{
                        align-items: center;
                        margin-top:20px;
                        .pt_img{
                            width:100%;
                            text-align: center;
                            img{
                                height:32px;
                                margin-bottom:2px;
                            }
                        }
                    }
                }
                .graph{
                    margin-top:60px;
                }
                .video_link_item{
                    margin-top:60px;
                    flex-direction: column;
                    img{
                        width:100%;
                    }
                }
            }
        }
        .business{
            &.b1{
                 padding:100px $container_space 0px; 
                .divider_container{
                    padding:0px;
                }
                .product_items{
                    margin-top:80px;
                    grid-template-columns: repeat(1, 1fr);
                    .pr_item{
                        border-radius: 8px;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-items: flex-end;
                        gap:0px;
                        height:initial;
                        .pr_name{
                            width:100%;
                            font-size: 21px;
                            line-height: 25px;
                            padding:5px 0px 18px;
                            border-bottom: 1px solid rgba(6, 25, 51, 0.5);
                            br{
                                display:none;
                            }
                            height:initial;
                        }
                        .pr_desc{
                            border-top:none;
                            margin-top:0px;
                            
                            font-size: 11px;
                            line-height: 16px;
                            flex:1;
                            height:160px;
                        }
                        .pr_img{
                            width:120px;
                            margin-left:10px;
                            img{
                                width:100%;
                            }
                        }
                    }
                }
            }
            &.b2{
                margin-top:0px;
                padding:0px;
                .tech_items{
                    margin-top:75px;
                    flex-direction: column;
                
                    .tech_item{
                        flex-direction: column;
                        gap:23px;
                        padding-right:0px;
                        height:100%;
                        
                        .tech_number{
                            font-size:44px;
                            line-height: 28px;;
                        }
                        .tech_title{
                            margin-top:15px;
                            font-size:22px;
                            line-height: 31px;
                            width:100%;
                        }
                        .tech_contents{
                            margin-top:10px;
                            font-size:15px;
                            line-height: 24px;
                            padding-left:0px;
                        }
                    }
                }
            }
        }
        .contact{
            .map{
                margin-top:30px;
            }
            &.c1{
                padding:0px $container_space 0px;
                flex-direction: column;
                .divider_container{
                    width:100%;
                    position: relative;
                    padding:0px;
                    
                   
                    .map_img{
                        position: absolute;
                        top:480px;

                    }
                    .contact_items{
                        .ct_item{
                            
                            gap:15px;
                            justify-content: flex-start;
                            padding-top:2px;
                            padding-bottom:38px;
                         
                            &.first_item{
                                height:initial;
                                padding-bottom:20px;
                              
                                .ctc_item{
                                    flex-direction: column;
                                    gap: 10px;
                                }
                            }
                            .ct_contents{
                                padding-top:5px;
                                width:initial;
                                flex:1;
                            }
                            .ct_country{
                                font-size:28px;
                                line-height: 38px;
                                width:115px;
                                flex:initial;

                            }
                            .ct_name{
                                width:100%;
                                // br{
                                //     display:none;
                                // }
                                .mobile_view{
                                    br{
                                        display:block;
                                    }
                                }
                            }
                            .ct_tel{
                                width:100%;
                            }
                            .video_link_item{
                                min-width:100%;
                                margin-top:10px;
                                margin-bottom:0px;
                                img{
                                    width:100%;
                                
                                }
                            }
                        }  
                    }
                }
              
            }

        }
        .divider_container{
            flex-direction: column;
            gap:22px;
            padding:0px $container_space;
            margin-bottom:95px;
            .section_title{
                width:100%;
                //border-top:1px solid rgba(255,255,255,0.5);
                font-size:22px;
                line-height: 24px;
                letter-spacing: 0.04em;
            }
            .section_desc{
                border-top:none;
                margin-top:0px;
                padding-top:0px;
                font-size:15px;
                line-height: 24px;
                .sub_title{
                    font-size:54px;
                    line-height: 54px;
                }
                .text_wrap{
                    padding-right:0px;
                }
            }
        }
        .q_section{
            padding:150px $container_space;
            gap:10px;
            img{
                width:30px;

            }
            .q_message{
                font-size:50px;
                line-height: 49px;
                margin-bottom:12px;
              
                br{
                    display:none;
                }
            }
            .q_writer{
                margin-top:25px;
                font-size:13px;
                line-height: 20px;
            }
        }
    }
    .footer{
        padding:0px $container_space;
        .ft_container{
            padding:14px 0px;
        }
    }
    .video_wrap{
        .logo{
            img{
                width:131px;
                top:37px;
                left:35px;
                z-index: 10;
            }
        }
        .circle_section{
            position: absolute;
            left:-543px;
            top:-350px;
            img{
                width:100%;
            }
            .vi1{
                position: absolute;
                top:0px;
                left:0px;
                width:968px;
                
            }
            .vi2{
                position: absolute;
                top:242px;
                left:347px;
                width:968px;
            }
        }
    }

}