$container_space:80px; //페이지별 좌우 기본 여백
$black:#061933;
body {
    color: #fff;
    font-family:"Neue Haas Grotesk Disp", sans-serif;
    font-weight:300;
    padding: 0px;
    margin: 0px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    word-break: keep-all;
    font-size: 15px;
    background-color: $black;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;   
}



h1,
h2,
h3,
input,
select,
ul,
li,
button {
    margin: 0px;
    padding: 0px
}

button {
    color: inherit;
    border: none;
    background: none;
}

ul,
li {
    list-style: none;
}
a{
    color:inherit;
    text-decoration: none;;
}

.mobile_view{
    display:none;
}
.ls_t{
    letter-spacing: 0px;
}

header{
    position: fixed;
    top:60px;
    left:0px;
    right:0px;
    display:flex;
    justify-content: space-between;
    z-index: 99;
    max-width: 1600px;
    padding:0px $container_space;
    box-sizing: border-box;
    margin:0px auto;
    .main_menu{
        display:flex;
        gap:40px;
        li{
            font-size:20px;
            line-height: 39px;
            font-weight:500;
            cursor: pointer;
            letter-spacing: 0.04em;
            position: relative;
            &.active,&:hover{
                &:after{
                    opacity: 1;
                }
            }
            &:after{
                position: absolute;
                width:100%;
                left:0px;
                bottom:15px;
                content: "";
                border-bottom:1px solid #fff;
                opacity: 0;
                transition-duration: 0.3s;
            }
        }
    }
    .mobile_menu{
        display:none;
    }
  
}
.pop_video_section{
    position: fixed;
    z-index: 999;
    left:0px;
    display:flex;
    justify-content: center;
    align-items: center;
    right:0px;
    bottom:0px;
    top:0px;
    background-color: rgba(0,0,0,0.7);
    .video_section{
        width:100%;
        line-height: 0px;
      
    
        iframe{
            pointer-events: none;
            position:absolute;
            top:0;left:0;width:100%;height:100%;
        }
        img{
            width:100%;
        }
    }
}
.contents_wrap{
    max-width: 1600px;
    margin:0px auto;
    min-height: 100vh;;
    
   
    .cc_1,.cc_2{
        display:inline-block;
        width:1418px;
        height:1418px;
        transform-origin: center center;
        position: absolute;
    }
    .back_img{
        position: fixed;
        right:0;
        left:0px;
        top:0px;
        z-index: -1;
        width:100%;
        bottom:0px;
        display:flex;
        justify-content: flex-end;
        align-items: flex-start;
        &.back1{
            .back_container{
                transform: translate(20%, -800px);
                .cc_2{
                    top:550px;
                }
                img{
                    
                 
                    // &.circle2, &.circle3{
                    //     top:550px;
                    // }
                   
                }
            }
        }
        &.back2{

            .back_container{
                transform: translate(-60%, -1200px);
                .cc_1,.cc_2{
                   
                    width:2012px;
                    height:2012px;
                }
                .cc_2{
                    top:75px;
                    left:775px;
                }
               
            }
        }
        &.back3{
            .back_container{
                transform: translate(-30%, -1000px);
                .cc_1,.cc_2{
                   
                    width:1570px;
                    height:1570px;
                } 
                .cc_2{
                    top:422px;
                    left:437px;
                }
               
            }
        }
        &.back4{
            img{
                width: 100%;
height: 100%;
object-fit: cover;
            }
        }
        .back_container{
            position: relative;
            width:100%;
            height:100%;
            
            img{
                position: absolute;
                top:0px;
                left:0px;
               
            }
        }
      
        img,svg{
            // width:100%;
            // height:100%;
            // object-fit: cover;
            #circle1,#circle2,#circle3{
                transform-origin: center center;
            }
        }
    }
    .q_section{
        padding:6vw $container_space;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap:12px;
       
        font-weight: 400;
        font-size: 104px;
        line-height: 96px;
 
        .q_message{
            margin-top:2px;
            margin-bottom:18px;
            font-family: 'Times';
            letter-spacing: -0.005em;
        }

        .q_writer{
            margin-top:42px;
            font-size: 18px;
            line-height: 30px;
            color: #A8A8B2;
            text-align: center;
            letter-spacing: 0.04em;
            opacity: 0.5;
            font-weight:300;
            
        }
    }
  
     
  
    .main{
        &.m1{
            padding:180px $container_space 0px;
            .main_copy{
                font-family: 'Times';
                font-size:104px;
                line-height: 96px;
                letter-spacing: -0.005em;
                color: #FF4E19;
            }
            .col2_section{
                margin-top:131px;
                display:flex;
                padding:0px;
                justify-content: space-between;
                .section_item{
                    width:550px;
                    margin-top:-8px;
                    .section_title{
                        font-size:34px;
                        line-height: 48px;
                        letter-spacing: 0.04em;
                        font-weight:500;
                    }
                    .section_contents{
                        margin-top:32px;
                        font-size: 20px;
                        line-height: 34px;
                        letter-spacing: 0.06em;
                    }
                }
                .spec_items{
                    width:550px;
                    .spec_item{
                        padding:2px 0px 20px;
                        height:115px;
                        box-sizing: border-box;
                        border-top: 1px solid rgba(255, 255, 255, 0.5);
                        font-family: 'Times';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 56px;
                        line-height: 75px;
                
                    }
                }
            }
        }
        &.m2{
            
            .q_section{
                .q_message{
                    margin-bottom:10px;
                }
            }
        }
    }
    .divider_container{
        display:flex;
        gap:50px;
        margin-bottom:225px;
        .section_title{
            width:20%;
            font-size:34px;
            font-weight:500;
            letter-spacing: 0.05em;
        }
        .section_desc{
            flex:1;
            font-size:24px;
            line-height: 38px;
            letter-spacing: 0.05em;
            margin-top:-8px;
            .text_wrap{
                padding-right:20%;
            }
            .sub_title{
                font-family: 'Times';
                color:#FF4E19;
                font-size:80px;
                line-height: 82px;
                letter-spacing: -0.005em;
            }
        }

    }
    .about{
       
        &.a1{
            padding:180px $container_space 50px;
            .main_copy{
                font-family: 'Times';
                font-size:200px;
                line-height: 179px;
                letter-spacing: -0.005em;
                color: #FF4E19;
                img{
                    width:804px;
                }

            }
            .main_desc{
                font-family: 'Times';
                margin-top:210px;
                font-size:44px;
                line-height: 51px;
                padding-left:calc(20% + 50px);
                padding-right:15%;
            }
        }
        &.a2{
            margin-top:100px;
            padding:0vw $container_space;
            .herit_items{
                display:flex;
                gap:20px;
                margin-top:155px;
                .herit_item{
                    flex:1;
                    border-top:1px solid rgba(255,255,255,0.5);
                    padding-top:20px;
                    .herit_title{
                        font-size:24px;
                        line-height:36px;
                        letter-spacing: 0.06em;
                        height:110px;
                    }
                    .herit_contents{
                        font-family: 'Times';
                        font-size: 32px;
                        line-height: 43px;
                        letter-spacing: -0.005em;
                        display:flex;
                        align-items: flex-end;
                        gap:8px;
                        .herit_value{
                            
                            font-size:88px;
                            line-height: 80px;
                            letter-spacing: -0.03em;
                        }
                        .herit_plus{
                            font-family:"Neue Haas Grotesk Disp", sans-serif;
                            font-size:80px;
                            font-weight:200;
                            line-height: 60px;
                            margin-left:-4px;
                        }
                    }

                }
            }
            .partner_title{
                margin-top:100px;
                font-size:24px;
                letter-spacing: 0.06em;
                padding-bottom:36px;
               
            }
            .partner_container{
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .partner_items{
                    border-top: 1px solid rgba(255, 255, 255, 0.5);
                    width:calc(50% - 10px);
                    .partner_item{
                        font-family: 'Times';
                        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                        padding:6px 0px 9px;
                        font-size:48px;
                        line-height: 63px;
                        letter-spacing: 0px;
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        img{
                            height:47px;
                        }
                      
                    }
                }
                .partner_imgs{
                    width:100%;
                    margin-top:38px;
                    display:flex;
                    justify-content: space-between;
                    .pt_img{
                        width:calc(50% - 10px);
                    }
                    img{
                        height:46px;
                    }
                }
            }
           
            .graph{
                margin-top:70px;
            
                img{
                    width:100%;
                }
            }
            .graph{
                border-top:1px solid rgba(255, 255, 255, 0.5);
                padding-top:20px;
                margin-top:100px;
                
            }
           
            
        }
        &.a3{
            .q_section{
                padding-top:0px;
            }
            .q_message{
                max-width: 1100px;
            }
        }
    }
    .business{
        &.b1{
             padding:185px $container_space 0px;
             .product_items{
                 margin-top:155px;
                 display:grid;
                 grid-template-columns: repeat(4,1fr);
                 gap:22px;
                 .pr_item{
                    color:$black;
                    background: rgba(248, 248, 245, 0.8);
                    border-radius: 14px;
                    height:400px;
                    padding:15px 20px;
                    box-sizing: border-box;;
                    display:flex;
                    flex-direction: column;
                    justify-content: space-between;
                     .pr_name{
                         font-size:24px;
                         line-height: 32px;
                         
                      
                        
                     }
                     .pr_desc{
                         margin-top:25px;
                         border-top:1px solid rgba(6,25,51,0.5);
                         padding-top:10px;
                         font-size:12px;
                         line-height: 20px;
                         flex:1;
                         letter-spacing: 0.035em;
                     }
                     .pr_img{
                         img{
                            width:140px;
                         }
                     }
                 }
             }
        }
        &.b2{
            padding:230px $container_space 0px;
            .divider_container{
                margin-bottom:60px;
            }
            .tech_items{
                margin-top:155px;
             
                .tech_item{
                    display:flex;
                    padding:20px 0px 40px 0px;
                    height:314px;
                    box-sizing: border-box;
                  
                    border-top:1px solid rgba(255, 255, 255, 0.5);
                    gap:30px;
                    .tech_number{
                        font-family: 'Times';
                        font-size: 80px;
                        line-height: 80px;;
                    }
                    .tech_title{
                        font-size:28px;
                        line-height: 41px;
                        width:260px;
                        letter-spacing: 0.04em;
                    }
                    .tech_contents{
                        flex:1;
                        padding-left:100px;
                        box-sizing: border-box;
                        font-size:16px;
                        line-height: 25px;
                        letter-spacing: 0.06em;
                    }

                }
            }
        }
    }
    .contact{
        .divider_container{
            margin-bottom:50px;
        }
        .map{
            margin-top:70px;
        
            img{
                width:100%;
            }
        }
        &.c1{
                 padding:180px $container_space 48px;               
                 .map{
                   width:100%;
                    
                    box-sizing: border-box;
                    font-size:0px;
                    margin-bottom:20px;
                    img{
                        max-width: 100%;
                    }
                }
                    .left_section{
                        width:40%;
                       .section_title{
                        
                        font-family: 'Times';
                        font-size: 80px;
                        line-height: 90px;
                        letter-spacing: -0.005em;
                        height:350px;
                       }
                       
                       
                    }
                    .section_desc{
                        flex:1;
                        margin-top:0px;
                        border-top:1px solid rgba(255, 255, 255, 0.25);
                        .ct_item{
                            
                            padding-top:16px;
                            padding-bottom:40px;
                            display:flex;
                            gap:40px;
                            justify-content: flex-end;
                            font-size:14px;
                            line-height: 23px;
                            letter-spacing: 0.06em;
                            box-sizing: border-box;
                            flex-wrap:wrap;

                            &.first_item{
                                // margin-top:7px;
                                // border-top:none;
                                // padding-top:0px;
                                height:350px;
                                
                                .ct_contents{
                                    flex-direction: column;
                                }
                                .ctc_item{
                                    display:flex;
                                    flex:1;
                                    margin-bottom:20px;
                                    
                                }

                         
                            }
                            &.top_line_item{
                                //border-top:1px solid rgba(255,255,255,0.25);
                            }
                            .ct_contents{
                                width:60%;
                                display:flex;
                                flex-wrap:wrap;
                            }
                            .ct_country{
                                font-size:40px;
                                line-height: 40px;
                                color:#FF4E19;
                                flex:1;
                                letter-spacing: -0.005em;
                                font-family: 'Times';
                            }
                            .ct_name{
                                flex:1;
                                min-width: 182px;
                            }
                            .ct_tel{
                                width:50%;
                                min-width: 182px;
                            }
                            .video_link_item{
                                margin-top:0px;
                                width:60%;
                                min-width: 364px;
                                display:flex;
                                margin-bottom:20px;
                                box-sizing: border-box;
                                
                                img{
                                    width:370px;
                                    cursor: pointer;
                                    
                                }
                            }
                           .strong_t{
                               font-weight:600;
                           }
                        }
                    }
            
               
        }
    }
}

footer{
    width:100%;
    padding:0px;
    box-sizing: border-box;
    height:62px;
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.04em;
    background: #030F20;
    .ft_logo{
        opacity: 0.5;
    }
    .ft_container{
        max-width: 1600px;
        margin:0px auto;
        display:flex;
        padding:14px $container_space;
        box-sizing: border-box;
        color:#A8A8B2;
        opacity: 0.5;
        justify-content: space-between;
        align-items: flex-start;
       
    }

}

.video_wrap{
    position: relative;
    .logo{
        img{
            width:692px;
            position: absolute;
            top:53px;
            left:50px;
        }
    }
    .circle_section{
        position: absolute;
        right:-22px;
        top:-170px;
        width:870px;
        img{
            width:100%;
        }
        .vi1{
            position: absolute;
            top:0px;
            left:0px;
            
        }
        .vi2{
            position: absolute;
            top:338px;
            left:0px;
        }
    }
}




// @media only screen and (min-width: 1000px) and (max-width: 1640px){
//     .contents_wrap{
//         .main{
//             &.m1{
//               .col2_section{
//                 .spec_items{
//                     width:40%;
//                 }
//               }
//             }
//         }
//         .business{
//             &.b1{
//                 .product_items{
//                     grid-template-columns: repeat(4,1fr);
//                 }
//             }
//             &.b2{
//                 .tech_items{
//                     .tech_item{
//                         padding-right:0px;
//                     }
//                 }
//             }
//         }
//     }

// }
// @media only screen and (min-width: 1000px) and (max-width: 1430px){
//     .contents_wrap{
//         .divider_container{
//             .section_title{
//                 width:220px;
//             }
//         }
//     }
// }