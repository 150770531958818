@font-face{
    font-family:"Neue Haas Grotesk Disp";
    font-weight:200;
    src:url("./Fonts/5548933/e5fafe20-c04f-491d-9282-5589caa6d41a.woff2") format("woff2"),url("Fonts/5548933/63e11a51-faff-42a4-a84a-90aae5a38b49.woff") format("woff");
}
@font-face{
    font-family:"Neue Haas Grotesk Disp";
    font-weight:300;
    src:url("./Fonts/5548962/47e29da7-7a58-44de-8b3c-db20c65f6c3f.woff2") format("woff2"),url("Fonts/5548962/b760d559-b712-4db0-a54c-0d0f8c10495b.woff") format("woff");
}
@font-face{
    font-family:"Neue Haas Grotesk Disp";
    font-weight:500;
    src:url("./Fonts/5549029/6e329389-9c44-48b0-8291-f918840fe862.woff2") format("woff2"),url("Fonts/5549029/dc6a6646-e0ac-4deb-b3c0-19e5dc30bf6a.woff") format("woff");
}
@font-face{
    font-family:"Neue Haas Grotesk Disp";
    font-weight:600;
    src:url("./Fonts/5548984/408d741e-b96a-486f-907a-770f7e11d6d3.woff2") format("woff2"),url("Fonts/5548984/ec7b458e-6d24-4b3d-9c7f-43321a10ffe4.woff") format("woff");
}
@font-face{
    font-family:"Times";
    src:url("./Fonts/5611776/bd76991f-f760-4d80-beae-6aab15646eaf.woff2") format("woff2"),url("Fonts/5611776/04370f41-f584-4e4d-93f4-c9efb669fc6e.woff") format("woff");
}
